import React, { useRef } from 'react'
import Props from 'prop-types'
import Validator from 'validator'

class FloatInput extends React.Component {

    state = {
        label: '',
        className: ""
    }

    componentDidMount(){
        if(this.props.defaultValue){
            this.mRef.value = this.props.defaultValue;
        }
    }

    onBlur = () => {
        if (this.props.onBlur)
            this.props.onBlur()
        let className = "";
        let required = this.props.required;
        let value = this.mRef.value;
        switch (this.props.type) {
            case "email":
                if (!value) {
                    if (required)
                        className = "invalid";
                    break
                }
                if (!Validator.isEmail(value)) {
                    className = "invalid";
                    break
                } else {
                    className = "valid";
                    break
                }
            case "password": {
                if (value && value.length > 4) {
                    className = "valid";
                } else {
                    className = "invalid";
                }
                break
            }
            case "text":
            default:
                if (required) {
                    if (value)
                        if (this.props.customValidator) {
                            if (this.props.customValidator(value))
                                className = "valid";
                            else
                                className = "invalid"
                        } else {
                            className = "valid";
                        }
                    else
                        className = "invalid";
                } else {
                    if (value) {
                        className = "valid";
                    }
                }
                break
        }
        this.setState({
            ...this.state,
            className
        })
    }


    updator = val => {
        this.mRef.value = val;
        this.setState({
            ...this.state,
            className: ""
        })
    }

    getValue = () => {
        return this.mRef.value;
    }


    render() {

        return (
            <div className="full-width">
                <label className="floating-top-label" style={this.props.labelStyle}>
                    {
                        this.props.title
                    }
                </label>
                <div style={this.props.style} className={`floating-label ${this.state.className}`}>
                    <input {...this.props.inputProps} style={this.props.inputStyle} onBlur={() => {
                        this.onBlur()
                    }} ref={ref => {
                        this.mRef = ref;
                        if (this.props.handleRef) {
                            this.props.handleRef({
                                ref,
                                setValue: this.updator,
                                float: true,
                                value: () => ref.value
                            })
                        }
                    }} min="1" autoComplete='off' type={this.props.type} placeholder={this.props.title} onChange={e => {
                        this.onBlur()
                        if (this.props.onChange) {
                            this.props.onChange(e)
                        }
                    }} />
                </div>
            </div>
        )
    }
}

FloatInput.propTypes = {
    title: Props.string.isRequired,
    type: Props.string.isRequired,
    handleRef: Props.func.isRequired,
    customValidator: Props.func
}

FloatInput.defaultProps = {
    required: true,
    inputProps: {

    }
}



class FloatSelect extends React.Component {

    state = {
        label: '',
        className: ""
    }

    updator = val => {
        this.mRef.value = val;
        this.setState({
            ...this.state,
            className: ""
        })
    }

    getValue = () => {
        return this.mRef.value;
    }

    getKey = () => {

    }


    value = () => {
        return this.mRef.value;
    }


    render() {

        return (
            <div className="full-width" style={this.props.style}>
                {
                    this.props.title ? <label className="floating-top-label" style={this.props.labelStyle}>
                        {
                            this.props.title
                        }:
					</label> : null
                }
                <div className={`floating-label`} style={{ ...this.props.labelInput }}>
                    <select onChange={e => {
                        if (this.props.onChange) {
                            this.props.onChange(this.value())
                        }
                    }} style={{
                        flex: 1,
                        border: 'none',
                        fontWeight: 400,
                        fontSize: 15,
                        color: 'var(--text-color)',
                        paddingLeft: 10
                    }} ref={ref => {
                        this.mRef = ref;
                        if (this.props.handleRef) {
                            this.props.handleRef({
                                ref,
                                setValue: this.updator,
                                value: () => ref.value
                            })
                        }
                    }} placeholder={this.props.title}>
                        {
                            this.props.items.map(item => (
                                <option value={item.key} key={item.key}>{item.value}</option>))
                        }
                    </select>
                </div>
            </div>
        )
    }
}


class FloatArea extends React.Component {

    state = {
        label: '',
        className: ""
    }


    onBlur = () => {
        if (this.props.onBlur)
            this.props.onBlur()
        let className = "";
        let required = this.props.required;
        let value = this.mRef.value;
        switch (this.props.type) {
            case "email":
                if (!value) {
                    if (required)
                        className = "invalid";
                    break
                }
                if (!Validator.isEmail(value)) {
                    className = "invalid";
                    break
                } else {
                    className = "valid";
                    break
                }
            case "password": {
                if (value && value.length > 4) {
                    className = "valid";
                } else {
                    className = "invalid";
                }
                break
            }
            case "text":
            default:
                if (required) {
                    if (value)
                        if (this.props.customValidator) {
                            if (this.props.customValidator(value))
                                className = "valid";
                            else
                                className = "invalid"
                        } else {
                            className = "valid";
                        }
                    else
                        className = "invalid";
                } else {
                    if (value) {
                        className = "valid";
                    }
                }
                break
        }
        this.setState({
            ...this.state,
            className
        })
    }


    updator = val => {
        this.mRef.value = val;
        this.setState({
            ...this.state,
            className: ""
        })
    }

    getValue = () => {
        console.log(this.mRef)
        return this.mRef.value;
    }


    render() {

        return (
            <div className="full-width">
                <label className="floating-top-label" style={this.props.labelStyle}>
                    {
                        this.props.title
                    }
                </label>
                <div style={this.props.style} className={`floating-label ${this.state.className}`}>
                    <textarea {...this.props.inputProps} style={
                        {
                            minHeight: 150,
                            padding: 15,
                            ...this.props.inputStyle ? this.props.inputStyle : {}
                        }
                    }
                        onBlur={() => {
                            this.onBlur()
                        }} ref={ref => {
                            this.mRef = ref;
                            if (this.props.handleRef) {
                                this.props.handleRef({
                                    ref,
                                    setValue: this.updator,
                                    value: () => this.getValue()
                                })
                            }
                        }} min="1" autoComplete='off' type={this.props.type} placeholder={this.props.title} onChange={e => {
                            this.onBlur()
                            if (this.props.onChange) {
                                this.props.onChange(e)
                            }
                        }} />
                </div>
            </div>
        )
    }
}
function ToggleSwitch(props) {

	let Toggle = useRef(null);

	return (
		<>
			<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,...props.style}}>
				<div style={{alignSelf: 'end', paddingBottom: 7, fontSize: '1rem'}}>
					{props.label}
				</div>
				<div style={{marginLeft: 20}}>
					<label style={{cursor:'pointer'}}  className={props.size==='S'?"switch-mini":"switch"}>
						<input  type="checkbox"
							   ref={ref => {
								   Toggle.current = ref;
								   if (props.handleRef) {
									   props.handleRef({
										   ref,
										   toggle: val => ref.checked = !ref.checked,
										   isChecked: () => ref.checked
									   })
								   }
							   }}
							   onChange={()=>{
								   if(props.callback){
									   props.callback(Toggle.current.checked);
								   }
							   }}
						checked={props.status}
						/>
						<span className={`${props.size==='S'?'togglerSlider-mini':'togglerSlider'} round`}/>
					</label>
				</div>
			</div>

		</>
	)
}


// class ToggleSwitch extends React.Component {

//     updator = val => {
//         this.mRef.value = val;
//         this.setState({
//             ...this.state,
//             className: ""
//         })
//     }

//     getValue = () => {
//         return this.mRef.value;
//     }

//  render() {
// 	return (
// 		<>
// 			<div style={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,...this.props.style}}>
// 				<div style={{alignSelf: 'end', paddingBottom: 7, fontSize: '1rem'}}>
// 					{this.props.label}
// 				</div>
// 				<div style={{marginLeft: 20}}>
// 					<label style={{cursor:'pointer'}}  className={this.props.size==='S'?"switch-mini":"switch"}>
// 						<input  type="checkbox"
// 							  ref={ref => {
//                                 this.mRef = ref;
//                                 if (this.props.handleRef) {
//                                     this.props.handleRef({
//                                         ref,
//                                         setValue: this.updator,
//                                         value: () => this.getValue()
//                                     })
//                                 }
//                             }}
// 							   onChange={()=>{
//                                 if(this.props.onChange) {
// 									  this.props.onChange();
//                                 }
// 							   }}
// 					    	checked={this.props.status}
// 						/>
// 						<span className={`${this.props.size==='S'?'togglerSlider-mini':'togglerSlider'} round`}/>
// 					</label>
// 				</div>
// 			</div>

// 		</>
// 	)
// }
// }

FloatSelect.propTypes = {
    title: Props.string,
    handleRef: Props.func.isRequired,
    items: Props.array.isRequired
}


export {
    FloatInput, FloatSelect, FloatArea, ToggleSwitch
}
