import {showToast} from "../../Components/Common/Toast/ToastCallback";

class Presentor {

    constructor(Model) {
        this.Model = Model;
    }

    setView(View) {
        this.View = View;
    }

	sendResetLink = data => {
        this.Model.sendLink(data,(e,r)=>{
            if(r){
                showToast({
                    type:'success',
                    message:"Reset link sent successfully!"
                })
            }
        })
    }

}

export default Presentor