import React from "react";
import {connect} from "react-redux";
import {attemptlogin} from "../../Redux/Actions/LoginController";
import Validator from "validator";
import {FloatInput} from "../../Components/Common/FloatLabelInput";
import Login from './assets/login_1.jpg'
import {showToast} from "../../Components/Common/Toast/ToastCallback";

class View extends React.Component {

	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
	}

	state = {
		on: false,
		name: undefined,
		password:false
	}

	attemptLogin = () => {
		let email = this.mEmail.value;
		let pass = this.mPass.value;
		if (!Validator.isEmail(email)) {
			showToast({
				type:'error',
				message:"Please enter a valid email address"
			})
		}
		if (!pass || pass.length < 4) {
			showToast({
				type:'error',
				message:"Please enter a valid password"
			})
			return;
		}

		this.props.login(email, pass)

	}


	handleEnterLogin = (e) => {
		if(e.key === "Enter") {
			this.attemptLogin();
		}
	}



	sendLink = () => {
		let email = this.mEmail.value();
		if(email){
			this.presentor.sendResetLink({
				email
			})
		}else{
			showToast({
				type:'error',
				message:"Please enter a valid email address"
			})
		}
	}

	render() {
		if (this.props.auth.authenticated) {
			const search = this.props.location.search
			const params = new URLSearchParams(search);
			const foo = params.get('next');
			if (foo) {
				this.props.history.push(foo)
			} else
				this.props.history.push("/app/admin")
		}
		return (
			<div onKeyPress = {this.handleEnterLogin}  style={{
				position: 'relative',
				background: '#EBF1FF',
				width:'100%',
				height:'100vh',
			}}>
				<div style={{background:"white",display:'flex',flexDirection:'row-reverse',justifyContent:'center',height:'100vh'}}>
				<img src={Login} style={{height:'100vh',display:'block',margin:"0 auto"}} alt="Login"/>
					<div style={{display:'flex',flex:1,alignItems:'center',flexDirection:'column',height:'100vh',justifyContent:'center'}}>
						{
							this.state.password?
							<div style={{
								display:'flex',
								alignItems:'center',
								flexDirection:'column'
					}}>
						<img alt="Eka Logo" src={require('./assets/eka_logo.png')} style={{width:175}}/>
						<h3 style={{fontWeight: 700, marginTop: 20, fontSize: 51, color: "#2C344B"}}>
							Forget Password
						</h3>
						<div style={{
							position:'relative',
							marginTop: 35,
							width:475,
							marginBottom: 55
						}}>
							<div style={{
								height:1,
								background:"#eeeeee",
								position:'absolute',
								left:0,
								top:10,
								right:0,
								zIndex:1
							}}/>
							<h4 style={{
								fontWeight: 400,
								textAlign:'center',
								background:'white',
								display:'inline',
								letterSpacing:1,
								zIndex:2,
								position:'absolute',
								left:'30%',
								paddingLeft:20,
								paddingRight:20,
								fontSize: 14,
								color: "#bebebe"
							}}>
								Send Reset Password Link
							</h4>
						</div>
						<div style={{width:475}}>
							<FloatInput handleRef={(ref) => {
								this.mEmail = ref
							}} type="email" title="Email Address"/>
							<div>
						<p onClick={()=>{
							this.setState({
								...this.state,
								password:false
							})
						}} style={{textAlign:'right',color:"#B883B2",cursor:'pointer',fontSize:15,marginTop:30}}>
							Already have credentials? Login!
						</p>
					</div>
							<button style={{width: "100%", marginTop: 35}} onClick={this.sendLink}
									className={`btn ${!this.props.auth.authenticated ? 'btn-portlet' : 'btn-success'}`}>
								{this.props.auth.authenticated ? 'Redirecting...' : 'SEND RESET LINK'}
							</button>
						</div>
					</div>:
					<div style={{
						display:'flex',
						alignItems:'center',
						flexDirection:'column'
			}}>
				<img alt="Eka Logo" src={require('./assets/eka_logo.png')} style={{width:175}}/>
				<h3 style={{fontWeight: 700, marginTop: 20, fontSize: 51, color: "#2C344B"}}>
					Welcome Back
				</h3>
				<div style={{
					position:'relative',
					marginTop: 35,
					width:475,
					marginBottom: 55
				}}>
					<div style={{
						height:1,
						background:"#eeeeee",
						position:'absolute',
						left:0,
						top:10,
						right:0,
						zIndex:2
					}}/>
					<h4 style={{
						fontWeight: 400,
						textAlign:'center',
						background:'white',
						display:'inline',
						letterSpacing:1,
						zIndex:2,
						position:'absolute',
						left:'30%',
						paddingLeft:20,
						paddingRight:20,
						fontSize: 14,
						color: "#bebebe"
					}}>
						LOGIN WITH EMAIL
					</h4>
				</div>
				<div style={{width:475}}>
					<FloatInput handleRef={({ref}) => {
						this.mEmail = ref
					}} type="email" title="Email Address"/>
					<FloatInput  title="Password" type="password" handleRef={({ref}) => this.mPass = ref}/>
					<div>
						<p onClick={()=>{
							this.setState({
								...this.state,
								password:true
							})
						}} style={{textAlign:'right',color:"#B883B2",cursor:'pointer',fontSize:15,marginTop:30}}>
							Forgot Your Password?
						</p>
					</div>
					<button style={{width: "100%", marginTop: 35}} onClick={this.attemptLogin}
							className={`btn ${!this.props.auth.authenticated ? 'btn-portlet' : 'btn-success'}`}>
						{this.props.auth.authenticated ? 'Redirecting...' : 'LOGIN'}
					</button>
				</div>
			</div> 
						}
					</div>
				</div>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (email, password) => {
			dispatch(attemptlogin(email, password))
		}
	}
})(View);