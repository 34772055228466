import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Store from '../Redux'
import {Provider} from 'react-redux';
import Login from './Login'
import ResetPassword from './ResetPassword'
import Redirect from './Dashboard'
import {asyncComponent} from "../Utils/Async";
import '../Components/Common/index.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-vis/dist/style.css';

const ReduxStore = Store();


const Index =
	(
		<Provider store={ReduxStore}>
			<BrowserRouter>
				<Switch>
					<Route exact={true}  path="/" component={Login}/>
					<Route exact={true}  path="/login" component={ResetPassword}/>
					<Route  path="/dashboard" component={Redirect}/>
					<Route  path="/app" component={asyncComponent(()=>import("./AuthenticatedController"))}/>
				</Switch>
			</BrowserRouter>
		</Provider>
	);

export default Index;
