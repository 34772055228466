import { get } from "../../../Utils/Crypto";

let api, analytics, mailer, pdf,analytic;

let DEVELOPMENT_MODE = false;

if (DEVELOPMENT_MODE) {
	api = "https://heimdall.expanso.in/support/";
	analytics = "https://heimdall.expanso.in/support/";
	mailer = "http://18.140.8.252:9191/api";

} else {
	api = "https://heimdall.eka.io/support/";
	analytics = "https://heimdall.eka.io/support/";
	mailer = "http://18.140.8.252:9191/api";
	pdf = "http://18.140.8.252:9001/mail/upload/'";
	analytic = "https://analytics.eka.io/support"
}

if(window.location.href.includes('dev-super-dice-tech')) {
	api =  "https://api.dev.eka.io/support/"
}

export {
	api, analytics, mailer, pdf,analytic
}


export const token = () => get("refresh_token");
