import { analytic, analytics, api, mailer } from '../Api/config';
import * as Crypto from "../../../Utils/Crypto";
import axios from 'axios';
import qs from 'qs'

let isRefreshing = false;
axios.defaults.baseURL = api;
axios.defaults.timeout = 62500;
axios.interceptors.response.use(response => { return response; }, function (error) {
    const err = error.response;
    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        err.config.__isRetryRequest = true;
        const originalRequest = err.config;
        if (!isRefreshing) {
            isRefreshing = true;
            getRefreshToken()
                .then(function (success) {
                    onRefreshed(success.data.access_token)
                })
                .catch(function (_error) {
                    localStorage.clear()
                    window.location.reload();
                });
        }
        return new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                resolve(axios(originalRequest));
            });
        });
    } else {
        return Promise.reject(err);
    }
});

axios.interceptors.request.use(function (config) {
    const token = Crypto.get('token');
    if (token !== null && token !== "") {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

function getRefreshToken() {
    let refreshtoken = Crypto.get("refresh_token");
    if (!refreshtoken) {
        localStorage.clear()
        window.location.reload();
    }
    return axios.post(`${api}auth/token/refresh`, {
        token: refreshtoken
    });
}

const setTokens = (token) => {
    Crypto.set('token', token);
}

const post = (url, data, cb) => {
    axios.post(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}

const analyticPost = (url, data, cb) => {
    axios.post(`${analytic}${url}`, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}




const patch = (url, data, cb) => {
    axios.patch(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}
const put = (url, data, cb) => {
    axios.put(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}

let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
}

const onRefreshed = (token) => {
    setTokens(token);
    isRefreshing = false;
    refreshSubscribers = refreshSubscribers.filter(cb => {
        cb(token);
        return false;
    });
}

const get = (url, cb, data = {}) => {
    axios.get(url, {
        params: data,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    }).then(res => cb(null, res.data)).catch(cb);
}


const analyticsApi = (url, cb, data = {}) => {
    axios.get(`${analytics}${url}`, {
        params: data,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    }).then(res => cb(null, res.data)).catch(cb);
}

const getMailerApi = (url, cb, data = {}) => {
    axios.get(`${mailer
        }${url}`, {
        params: data,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    }).then(res => cb(null, res.data)).catch(cb);
}
export default axios;
export {
    post, get, axios, patch, put, analyticsApi, getMailerApi,analyticPost
}
